import { addDays, format } from 'date-fns'
import { R2GO_ORDER_EDIT_GRACE_PERIOD } from '@mindfulchefuk/constants'
import { ShopOrders } from '@mindfulchefuk/query'
import { useAvailableDates } from '@mindfulchefuk/features/Operations/hooks/useAvailableDates'
import getNextDate from '@mindfulchefuk/utils/getNextDate'

// Get the earliest next day shop delivery date:
// Orders made before 10am are next day
// Orders made at 10am or after are next day + 1
// Then check the date is not an unavailable date
// If the date is unavailable:
// Increment the date by 1 until we find a date which is available
export const useNextAvailableShopDeliveryDate = (
  shopOrders: ShopOrders = [],
  options = { enabled: true }
): string | undefined => {
  const unusableDatesAlreadyContainingShopOrders = shopOrders.reduce(
    (dates, item) =>
      !['basket', 'cancelled'].includes(item?.status)
        ? [...dates, item.deliveryDate]
        : dates,
    []
  )

  const { shop } = useAvailableDates()

  const earliestDeliveryDate = format(
    addDays(new Date(), R2GO_ORDER_EDIT_GRACE_PERIOD)
  )

  if (!options.enabled) return undefined

  return getNextDate({
    fromDate: earliestDeliveryDate,
    unavailableDates: [
      ...shop.unavailableDates,
      ...unusableDatesAlreadyContainingShopOrders,
    ],
  })
}
