import { logEvent } from '@mindfulchefuk/utils/analytics/'

export const logOnboardingCloseRecipeBasket = () => {
  logEvent({
    category: 'Recipe - Basket',
    action: 'Close basket - Clicked',
    label: 'Click',
  })
}

export const logOnboardingConfirmRecipesFromBasket = () => {
  logEvent({
    category: 'Recipe - Basket',
    action: 'Checkout basket - Clicked',
    label: 'Click',
  })
}

export const logOnboardingChooseRecipesFromBasket = () => {
  logEvent({
    category: 'Recipe - Basket',
    action: 'Empty basket choose recipes - Clicked',
    label: 'Click',
  })
}

export const logOnboardingIncrementRecipeFromBasket = () => {
  logEvent({
    category: 'Recipe - Basket',
    action: 'Add item',
    label: 'Quantity clicked',
  })
}

export const logOnboardingDecrementRecipeFromBasket = () => {
  logEvent({
    category: 'Recipe - Basket',
    action: 'Remove item',
    label: 'Quantity clicked',
  })
}

export const logOnboardingClearRecipesFromBasket = () => {
  logEvent({
    category: 'Recipe - Basket',
    action: 'Remove item',
    label: 'Product clicked',
  })
}
