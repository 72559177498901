import React, { useEffect, VoidFunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import {
  isProductType,
  isShopProductType,
} from '@mindfulchefuk/features/Products/productTypeConfig'
import ProductNavigation from '@mindfulchefuk/features/Products/components/ProductNavigation/ProductNavigation'
import useScrollToTop from '@mindfulchefuk/hooks/useScrollToTop'
import { logShopCategoryClicked } from '@mindfulchefuk/features/ShopOnboarding/utils/shopOnboardingAnalytics'
import { Text } from '@mindfulchefuk/design-system/Text'
import {
  Box,
  Container,
  IllustrationContainer,
} from '@mindfulchefuk/design-system'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import { useInstrumentReadyToGoViewed } from '@mindfulchefuk/utils/analytics/amplitude/instrument/hooks'
import { useUpdateProductQuantityOnboarding } from '@mindfulchefuk/features/ShopOnboarding/hooks/useUpdateProductQuantityOnboarding'
import { ProductsGrid } from '@mindfulchefuk/features/Products/components/ProductCard/ProductsGrid'
import { ProductPage } from '@mindfulchefuk/features/Delivery/pages/ProductPage'
import { closeModal } from '@mindfulchefuk/utils/modalUtils'
import { RootState } from '@mindfulchefuk/types/store'
import { ProductType, ProductWithQuantity } from '@mindfulchefuk/query'
import Loader from '@mindfulchefuk/components/Loader'
import { useGetShopProductsWithQuantities } from '@mindfulchefuk/hooks/useGetShopProductsWithQuantities'
import { instrumentProductDetailsClicked } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentDashboard'
import { ProductType as ProductTypeAnalytics } from '@mindfulchefuk/analytics/src/types'
import {
  ProductAnnouncementModal,
  ShopPromotionalBanner,
} from '@mindfulchefuk/features/CMS/components'

const ShopSelection: VoidFunctionComponent = () => {
  const router = useRouter()

  useInstrumentReadyToGoViewed()

  const productType = router.query.page as ProductType
  const productId = router.query.pid as string

  // Destructure replace here so the useEffect below isn't triggered by all router changes
  // Otherwise the redirect will run multiple times
  const { replace: routerReplace } = router
  const shouldRedirect = router.isReady && !isProductType(productType)

  useEffect(() => {
    if (shouldRedirect) {
      routerReplace({ query: { page: 'meal' as ProductType } })
    }
  }, [shouldRedirect, routerReplace, productId])

  useScrollToTop([])

  const isValidShopPage = isShopProductType(productType)

  const handleUpdateProductQuantity = useUpdateProductQuantityOnboarding()

  const handleOpenDetails = (product: ProductWithQuantity) =>
    instrumentProductDetailsClicked({
      pageSource: 'ready to go category page',
      productCategory: product.productType as ProductTypeAnalytics,
      productType: 'ready to go',
      productId: product.id,
      productName: product.name,
    })

  const lineItems = useSelector(
    (store: RootState) => store.shopOnboarding.lineItems
  )

  // Product data to display the products on the page
  const { data: productsWithQuantities, isFetched: productsFetched } =
    useGetShopProductsWithQuantities({
      type: productType,
      lineItems,
    })

  const productWithQuantity = productsWithQuantities?.find(
    (p) => p.id === productId
  )

  if (!productsFetched) {
    return <Loader />
  }

  return (
    <Box position="relative">
      <Container position="relative" py={40} overflow="hidden">
        <Illustration
          type="cross-section-lemon"
          left={{ base: -96, lg: -128 }}
          top={[-64, -80]}
        />
        <Box position="absolute" left={{ base: 256, sm: 384, md: 512 }} top={0}>
          <Illustration
            type="cross-section-mushroom-1"
            transform="rotate(-40deg) scale(0.9)"
            top={12}
          />
          <Illustration
            type="cross-section-mushroom-1"
            display={['none', 'block']}
            transform="rotate(-125deg) scale(0.9)"
            left={64}
            top={24}
          />
          <Illustration
            type="cross-section-coriander-1"
            display={['none', 'block']}
            transform="rotate(70deg) scale(0.5)"
            left={256}
            top={-48}
          />
        </Box>
        <Text as="h1" variant="body-lg-500-caps" color="aubergine">
          Ready to Go range
        </Text>
      </Container>

      <ProductNavigation
        type="onboarding"
        onItemClick={logShopCategoryClicked}
      />

      <Box overflow="hidden" mt={32}>
        <IllustrationContainer illustrations="07">
          <ShopPromotionalBanner page="page-shop-onboarding" />
          {isValidShopPage && (
            <ProductsGrid
              products={productsWithQuantities}
              productType={productType}
              onUpdateProductQuantity={handleUpdateProductQuantity}
              onOpenDetails={handleOpenDetails}
            />
          )}
          {isValidShopPage && typeof productId !== 'undefined' && (
            <ProductPage
              product={productWithQuantity}
              onClose={() => closeModal('pid')}
            />
          )}
        </IllustrationContainer>
      </Box>

      <ProductAnnouncementModal page="page-shop-onboarding" />
    </Box>
  )
}

export default ShopSelection
