import {
  logEvent,
  logProductAdd,
  logProductRemove,
  logPurchase,
} from '@mindfulchefuk/utils/analytics'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { ProductWithQuantity, ProductType } from '@mindfulchefuk/query'
import { Device } from '@mindfulchefuk/hooks/useDetectDevice'

export const addToCartPixel = (
  productType: ProductType,
  price: number,
  name: string,
  id: string
): void => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'AddToCart', {
      content_name: name,
      content_category: productType,
      content_ids: [id],
      content_type: productType,
      value: price,
      currency: 'GBP',
    })
  }

  window.ttq?.track('AddToCart', {
    price,
    value: price,
    currency: 'GBP',
    content_type: productType,
    content_id: id,
    content_name: name,
    content_category: productType,
  })
}

export const checkoutStartPixel = (): void => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'InitiateCheckout')
  }
}

export const logShopCheckoutStep = (step: number): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'mc.analytics.checkout.step',
      ecommerce: {
        checkout: {
          actionField: { step },
        },
      },
    })
  }
}

export const checkoutSuccessPixel = (
  total: number,
  products: { id: string; name: string; productType: ProductType }[]
): void => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'Purchase', {
      value: total,
      currency: 'GBP',
      content_ids: [products],
    })
  }
}

export const logShopCategoryClicked = (title: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopProducts,
    action: 'Shop category - Clicked',
    label: title,
  })
}

export const logShopAddToCart = (id: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopProductDetails,
    action: 'Add to Cart - Click',
    label: id,
  })
}

export const logAddToCart = (product: ProductWithQuantity): void => {
  const { id, name, price, productType } = product
  logEvent({
    category: ANALYTICS_CATEGORIES.shopProducts,
    action: 'Add to Cart - Clicked',
    label: id,
  })
  logProductAdd({
    name,
    id,
    price,
    brand: 'ready to go',
    category: productType,
    variant: 1,
    quantity: 1,
  })
}

export const logShopRemoveFromCart = (id: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopProductDetails,
    action: 'Remove from Cart - Click',
    label: id,
  })
}

export const logRemoveFromCart = (product: ProductWithQuantity): void => {
  const { id, name, price, productType } = product
  logEvent({
    category: ANALYTICS_CATEGORIES.shopProducts,
    action: 'Remove from Cart - Clicked',
    label: id,
  })
  logProductRemove({
    name,
    id,
    price,
    brand: 'ready to go',
    category: productType,
    variant: 1,
    quantity: 1,
  })
}

export const logOpenProductDetails = (product: ProductWithQuantity): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopProducts,
    action: 'Product details - Click',
    label: product.id,
  })
}

export const logOnCartClick = (itemsCount: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopProducts,
    action: 'Cart - Clicked',
    label: itemsCount,
  })
}

export const logCloseCart = (itemsCount: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopBasket,
    action: 'Close basket - Clicked',
    label: itemsCount,
  })
}
export const logProceedToCheckout = (itemsCount: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopBasket,
    action: 'Checkout - Clicked',
    label: itemsCount,
  })
}

export const logAddItemFromCart = (id: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopBasket,
    action: 'Add item - Clicked',
    label: id,
  })
}

export const logRemoveItemFromCart = (id: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopBasket,
    action: 'Remove item - Clicked',
    label: id,
  })
}

export const logRemoveAllItemFromCart = (id: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopBasket,
    action: 'Remove all item - Clicked',
    label: id,
  })
}

export const logIntoYourAccount = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopAboutYou,
    action: 'Log into your Account - Click',
  })
}

export const logEmailOptIn = (action: 'Tick' | 'Untick'): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopAboutYou,
    action: 'Email opt-in - Click',
    label: action,
  })
}

export const logAboutYouErrors = (errors: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopAboutYou,
    action: 'Add Delivery Details - Click',
    label: errors,
  })
}

export const logDynamicAddressUsed = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopShipping,
    action: 'Dynamic Address Search Used',
  })
}

export const logToggleAddressEntry = (action: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopShipping,
    action,
  })
}

export const logSafePlaceChosen = (value: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopShipping,
    action: 'Choose a safe place - Click',
    label: value,
  })
}

export const logShippingErrors = (errors: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopShipping,
    action: 'Go to Payment - Click',
    label: errors,
  })
}

export const logClearPaymentForm = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopPayment,
    action: 'Clear payment details - Click',
  })
}

export const logFailedSCA = (error: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopPayment,
    action: 'Failed SCA - View',
    label: error,
  })
}

export const logPaymentValidationError = (error: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopPayment,
    action: 'Payment form validation error - View',
    label: error,
  })
}

interface PaymentSuccessArgs {
  orderId: string
  items: ProductWithQuantity[]
  total: number
}
export const logPaymentSuccess = ({
  orderId,
  items,
  total,
}: PaymentSuccessArgs): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopPayment,
    action: 'Payment succeeded',
  })

  logPurchase({
    transactionId: `NEW R2G-${orderId}`,
    total,
    affiliation: 'Website',
    products: items.map(({ id, productType, name, quantity, price }) => ({
      id,
      brand: 'ready to go',
      currencyCode: 'GBP',
      category: productType,
      name,
      quantity,
      price,
    })),
  })
}

export const logPlaceOrderClicked = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopPayment,
    action: 'Place order - Click',
    label: 'No validation errors',
  })
}

export const logBackToShopClicked = (stepName: string): void => {
  logEvent({
    category: `Shop - ${stepName}`,
    action: 'Back to Shop - Click',
  })
}

export const logStepperIconClicked = (
  currentStep: string,
  clickedStep: string
): void => {
  logEvent({
    category: `Shop - ${currentStep}`,
    action: 'Progress bar - Click',
    label: clickedStep,
  })
}

export const logFAQIsClicked =
  (stepName: string) =>
  (question: string): void => {
    logEvent({
      category: `Shop - ${stepName}`,
      action: 'FAQ - Click',
      label: question,
    })
  }

export const logAppDownloads = (device: Device): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopSuccess,
    action: 'App Download Icon - Clicked',
    label: device,
  })
}

export const logSuccessfulOrder = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.shopSuccess,
    action: 'Successful order created',
  })
}

export const logNoPaymentModalOpen = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.MyAccountDetails,
    action: 'No payment modal',
    label: 'Modal displayed',
  })
}

export const logNoPaymentModalCta = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.MyAccountDetails,
    action: 'No payment modal',
    label: 'Add payment CTA clicked',
  })
}
