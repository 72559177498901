import { breakpointsArray, maxWidths } from '@mindfulchefuk/design-system'
import { CMSResponsiveImage } from '@mindfulchefuk/features/CMS/interfaces'

export const buildResponsiveBgImage = (
  responsiveImage: CMSResponsiveImage
): { base: string; sm?: string; md?: string } => {
  return buildResponsiveImageArray(responsiveImage).reduce(
    (responsiveImageObj, image, index) => {
      const currentBreakpoint = breakpointsArray[index]
      return {
        ...responsiveImageObj,
        [currentBreakpoint]: `url(${process.env.NEXT_PUBLIC_CMS_IMGIX_URL}/${image.path}?q=50)`,
      }
    },
    { base: '' }
  )
}

export const buildResponsiveImageSources = (
  responsiveImage: CMSResponsiveImage
) => {
  const altText = responsiveImage.alternativeText
  const sources = buildResponsiveImageArray(responsiveImage).reduce(
    (responsiveImageArray, image, index) => {
      const nextBreakpoint = breakpointsArray[index + 1]
      responsiveImageArray.push({
        media: maxWidths[nextBreakpoint],
        src: image.path,
        width: image.width,
      })

      return responsiveImageArray
    },
    []
  )

  return {
    // Default src to largest image
    src: sources.length ? sources[sources.length - 1].src : null,
    altText,
    sources,
  }
}

export const buildResponsiveImageArray = (
  responsiveImage: CMSResponsiveImage
) =>
  [
    responsiveImage.mobile,
    responsiveImage.tablet,
    responsiveImage.desktop,
  ].filter((image) => !!image?.path)
