import Router from 'next/router'

const Modals = ['recipe', 'pid'] as const

export const closeModal = (modal: typeof Modals[number]) => {
  const queries = new URL(window.location.href).searchParams
  queries.delete(modal)

  return Router.push(
    {
      pathname: window.location.pathname,
      query: queries.toString(),
    },
    undefined,
    { scroll: false, shallow: true }
  )
}

export const openModal = (modal: typeof Modals[number], value?: string) => {
  const queries = new URL(window.location.href).searchParams
  Modals.forEach((modalToRemove) => {
    queries.delete(modalToRemove)
  })
  queries.append(modal, value || 'true')

  return Router.push(
    {
      pathname: window.location.pathname,
      query: queries.toString(),
    },
    undefined,
    { scroll: false, shallow: true }
  )
}
