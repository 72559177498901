import React, { ReactElement } from 'react'
import { ProductWithQuantity } from '@mindfulchefuk/query'

import { useNewestItem } from '@mindfulchefuk/features/Cart/useNewestItem'
import { Box } from '@mindfulchefuk/design-system/layout'
import { CartButton } from './CartButton'
import { CartFlag } from './CartFlag'

type Props = {
  products?: ProductWithQuantity[]
  totalProducts: number
  logOnCartClick: () => void
  toggleDrawerOpen: () => void
}

const OnboardingShopCartNavigation = ({
  products,
  totalProducts,
  logOnCartClick,
  toggleDrawerOpen,
}: Props): ReactElement => {
  const newestItem = useNewestItem(products)

  return (
    <Box position="relative">
      <CartButton
        newestItem={newestItem}
        onClick={() => {
          toggleDrawerOpen()
          logOnCartClick()
        }}
      >
        {totalProducts}
      </CartButton>
      <CartFlag item={newestItem} />
    </Box>
  )
}

export default OnboardingShopCartNavigation
