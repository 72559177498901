import React from 'react'
import { Icon, Text, Box, Flex } from '@mindfulchefuk/design-system'
import { SpicinessLevel } from '@mindfulchefuk/features/Recipes/interfaces'

type Props = { spicinessLevel: SpicinessLevel }
type ChilliConfig = {
  text: 'MILD' | 'MED' | 'HOT'
  color: 'veg15' | 'veg12' | 'veg9'
}
type ChilliArray = [ChilliConfig, ChilliConfig, ChilliConfig]

const chillis: ChilliArray = [
  { text: 'MILD', color: 'veg15' },
  { text: 'MED', color: 'veg12' },
  { text: 'HOT', color: 'veg9' },
]

export const ChilliLevel = ({ spicinessLevel }: Props) => {
  if (!spicinessLevel) {
    return null
  }

  const chilliLevelIndex = spicinessLevel - 1
  const { color, text } = chillis[chilliLevelIndex]

  const mappingArray = new Array(spicinessLevel).fill(null)

  return (
    <Flex alignItems="center" mr={8}>
      {spicinessLevel ? (
        <>
          <Box marginTop={4}>
            {mappingArray.map((_value, index) => (
              <Icon
                data-testid="chilli"
                type="chilli"
                size={20}
                key={text[index]}
                color={color}
                marginLeft={index ? -8 : -2}
              />
            ))}
          </Box>
          <Text variant="body-sm-500-caps" marginX={2} color="aubergine">
            {text}
          </Text>
        </>
      ) : null}
    </Flex>
  )
}
