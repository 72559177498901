export const readFromLocalStorage = <T>({
  key,
  typeCheckCast,
  deleteAfter = false,
}: {
  key: string
  typeCheckCast: (maybeT: unknown) => T
  deleteAfter?: boolean
}): T => {
  try {
    const stringObj = window.localStorage.getItem(key)
    if (deleteAfter) window.localStorage.removeItem(key)
    if (stringObj === null) return undefined
    return typeCheckCast(JSON.parse(stringObj))
  } catch (e) {
    return undefined
  }
  return undefined
}

export const writeToLocalStorage = ({
  key,
  data,
}: {
  key: string
  data: unknown
}): void => {
  try {
    if (Object.keys(data).length === 0) {
      window.localStorage.removeItem(key)
    } else {
      window.localStorage.setItem(key, JSON.stringify(data))
    }
  } catch {
    return undefined
  }
  return undefined
}
