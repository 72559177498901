import React, { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'
import { useNextAvailableShopDeliveryDate } from '@mindfulchefuk/hooks/useNextAvailableShopDeliveryDate'
import { useGetShopOrders } from '@mindfulchefuk/features/ShopOrder/hooks/useGetShopOrders'

type Props = { redirectOverride?: boolean }

const ShopOnboardingRedirect: FC<Props> = ({ redirectOverride, children }) => {
  const router = useRouter()
  const { isFetched, isFeatureEnabled, isNewCustomer } = useIsNewCustomer()
  const existingCustomer = isFeatureEnabled ? isFetched && !isNewCustomer : true
  const { data: shopOrders } = useGetShopOrders()
  const nextAvailableDate = useNextAvailableShopDeliveryDate(shopOrders)

  useEffect(() => {
    if (!isAuthenticated() && router.isReady) {
      router.replace(
        'https://help.mindfulchef.com/en/articles/125-scheduling-a-ready-to-go-order'
      )
    }
  }, [nextAvailableDate, router])

  // Redirect to the next available shop products page if the user is already logged in
  useEffect(() => {
    async function redirectToNextAvailableDate() {
      // Remove step from the query params as not needed after redirect
      // Ensure page param defaults to 'meal' if not provided
      const { step, page = 'meal', ...otherQueryParams } = router.query

      router.replace({
        pathname: `${routesConfig.delivery.pathname(nextAvailableDate)}`,
        query: { page, ...otherQueryParams },
      })
    }

    if (
      router.isReady &&
      isAuthenticated() &&
      !redirectOverride &&
      existingCustomer
    ) {
      redirectToNextAvailableDate()
    }
  }, [redirectOverride, router, existingCustomer, nextAvailableDate])

  return <>{children}</>
}

export default ShopOnboardingRedirect
