import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import { format } from 'date-fns'
import { ProductWithQuantity } from '@mindfulchefuk/query'

import { Flex, Text, Button, Box } from '@mindfulchefuk/design-system'
import { Icon } from '@mindfulchefuk/design-system/Icon'
import {
  CartDrawerWrapper,
  CloseButton,
} from '@mindfulchefuk/features/Cart/components/CartDrawer/CartDrawerWrapper'
import { Recipes } from '@mindfulchefuk/features/Cart/components/CartDrawer/Recipes'
import { Products } from '@mindfulchefuk/features/Cart/components/CartDrawer/Products'
import { EmptyCart } from '@mindfulchefuk/features/Cart/components/CartDrawer/EmptyCart'

import { RootState } from '@mindfulchefuk/types/store'
import { getMinimumMaximumRecipesForPortion } from '@mindfulchefuk/services/getMinimumMaximumRecipesForPortion'
import getPaymentDate from '@mindfulchefuk/helpers/js/getPaymentDate'

import {
  logOnboardingChooseRecipesFromBasket,
  logOnboardingCloseRecipeBasket,
} from '@mindfulchefuk/utils/analytics/ga/onboardingRecipeBasket'

import {
  Footer,
  Article,
} from '@mindfulchefuk/features/Cart/components/CartDrawer/CardDrawer.styles'
import {
  RecipeWithQuantity,
  Recipe,
} from '@mindfulchefuk/features/Recipes/interfaces'
import { CartItemType } from '@mindfulchefuk/features/Cart/interfaces'
import {
  PORTION_COUNT_DEFAULT,
  R2G_FREE_DELIVERY_MIN_PRICE,
  R2G_SHIPPING_PRICE,
} from '@mindfulchefuk/constants'
import { useNextAvailableShopDeliveryDate } from '@mindfulchefuk/hooks/useNextAvailableShopDeliveryDate'
import { useGetShopOrders } from '@mindfulchefuk/features/ShopOrder/hooks/useGetShopOrders'

type RecipeCart = {
  type: CartItemType.RECIPE
  recipes: RecipeWithQuantity[]
  addRecipe: (item: Recipe) => void
  removeRecipe: (item: Recipe) => void
  removeAllRecipe: (item: RecipeWithQuantity) => void
  products?: ProductWithQuantity[]
  addProduct?: never
  removeProduct?: never
  removeAllProduct?: never
}

type ShopCart = {
  type: CartItemType.PRODUCT
  products: ProductWithQuantity[]
  addProduct: (id: string) => void
  removeProduct: (id: string) => void
  removeAllProduct: (id: string) => void
  recipes?: never
  addRecipe?: never
  removeRecipe?: never
  removeAllRecipe?: never
}

export type TOnboardingCardDrawer = {
  isDrawerOpen?: boolean
  toggleDrawerOpen: () => void
  onPressContinue: () => void
  total: number
  originalTotal?: number
  logCloseCart?: () => void
  orderShippingAmount?: number
} & (RecipeCart | ShopCart)

export const OnboardingCartDrawer = ({
  addProduct,
  addRecipe,
  isDrawerOpen = true,
  logCloseCart,
  onPressContinue,
  orderShippingAmount = 0,
  originalTotal,
  products = [],
  recipes = [],
  removeAllProduct,
  removeProduct,
  removeAllRecipe,
  removeRecipe,
  toggleDrawerOpen,
  total,
  type,
}: TOnboardingCardDrawer) => {
  const { items, deliveryDate, portionCount } = useSelector(
    (store: RootState) => ({
      items: store.basket.items,
      deliveryDate: store.basket.selectedDeliveryDate,
      portionCount: store.basket.portionCount,
    }),
    shallowEqual
  )

  const recipePaymentDate = getPaymentDate(deliveryDate)

  const { minimumRecipes, maximumRecipes } = getMinimumMaximumRecipesForPortion(
    { portionCount, journey: 'onboarding' }
  )

  const { RECIPE, PRODUCT } = CartItemType
  const selectedBasketPortionCount = portionCount || PORTION_COUNT_DEFAULT
  const hasRecipes = !!recipes.length
  const hasProducts = !!products.length
  const totalCartItems = products.length + recipes.length
  const cartIsEmpty = totalCartItems === 0
  const minRecipesNotReached = items.length < minimumRecipes

  const shippingPrice =
    R2G_FREE_DELIVERY_MIN_PRICE - total > 0 ? R2G_SHIPPING_PRICE : 0

  const shopOrderTotal = total + shippingPrice

  const { data: shopOrders } = useGetShopOrders()

  const nextProductsDate = useNextAvailableShopDeliveryDate(shopOrders)

  const renderFooterButton = () => {
    let footerButtonText
    let footerButtonHandler
    let isFooterButtonDisabled

    if (type === PRODUCT) {
      footerButtonHandler = onPressContinue
      footerButtonText = 'Checkout'
      isFooterButtonDisabled = cartIsEmpty
    }

    if (type === RECIPE) {
      isFooterButtonDisabled = false

      if (minRecipesNotReached) {
        footerButtonText = 'Choose Recipes'
        footerButtonHandler = () => {
          toggleDrawerOpen()
          logOnboardingChooseRecipesFromBasket()
        }
      } else {
        footerButtonText = 'Confirm Recipes'
        footerButtonHandler = onPressContinue
      }
    }

    return (
      <Button
        variant="primary"
        size={['medium', 'large']}
        type="button"
        disabled={isFooterButtonDisabled}
        onClick={footerButtonHandler}
        fullWidth
        data-testid="footer-button"
      >
        {footerButtonText}
      </Button>
    )
  }

  return (
    <CartDrawerWrapper isOpen={isDrawerOpen} onClose={toggleDrawerOpen}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderColor="pepper-50"
        borderBottomWidth={1}
        marginBottom={0}
        paddingY={{ base: 12, sm: 24 }}
        paddingX={24}
      >
        {type === PRODUCT && (
          <Flex flexDirection="column" paddingY={{ base: 12, sm: 0 }}>
            <Text
              as="h2"
              variant="body-md-500-caps"
              color="aubergine"
              data-testid="cart-heading"
            >
              Your basket
            </Text>

            <Text
              as="h2"
              variant="body-sm"
              color="aubergine"
              data-testid="cart-subheading"
            >
              Order for delivery on {format(nextProductsDate, 'dddd, MMM D')}
            </Text>
          </Flex>
        )}

        {type === RECIPE && (
          <Text as="h3" variant="heading-sm" color="aubergine">
            Order for delivery on {format(deliveryDate, 'dddd, MMM D')}
          </Text>
        )}

        <Flex
          height="100%"
          alignItems={{ base: 'flex-start', sm: 'center' }}
          paddingY={{ base: type === PRODUCT ? 12 : 0, sm: 0 }}
          flexDirection="column"
        >
          <CloseButton
            data-testid="cart-drawer-close-button"
            whileTap={{ scale: 0.9 }}
            onClick={(event) => {
              event.preventDefault()
              toggleDrawerOpen()
              logOnboardingCloseRecipeBasket()
              if (logCloseCart) {
                logCloseCart()
              }
            }}
          >
            <Icon type="cross" size={16} color="aubergine" />
          </CloseButton>
        </Flex>
      </Flex>
      <AnimatePresence mode="wait">
        <Article layout>
          {cartIsEmpty && <EmptyCart />}
          {hasRecipes && (
            <Recipes
              isOpen={isDrawerOpen}
              basketOriginalTotal={originalTotal}
              basketTotal={total}
              maxRecipes={maximumRecipes}
              minRecipes={minimumRecipes}
              paymentDate={recipePaymentDate}
              shippingPrice={orderShippingAmount}
              portionCountPerMeal={selectedBasketPortionCount}
              removeAllItem={(recipeId) => {
                removeAllRecipe(recipes.find((r) => r.id === recipeId))
              }}
              removeItem={(recipeId) => {
                removeRecipe(recipes.find((r) => r.id === recipeId))
              }}
              addItem={(recipeId) => {
                addRecipe(recipes.find((r) => r.id === recipeId))
              }}
              recipes={recipes}
              totalRecipes={recipes.reduce(
                (count, recipe) => count + recipe.quantity,
                0
              )}
              key="recipes"
              orderIsCompleted={false}
            />
          )}
          {hasProducts && (
            <Products
              orderIsCompleted={false}
              isOpen={isDrawerOpen}
              shopOrderTotal={shopOrderTotal}
              shopOrderTotalBeforeDiscounts={total}
              products={products}
              removeItem={removeProduct}
              removeAllItem={removeAllProduct}
              addItem={addProduct}
              key="products"
              shippingPrice={shippingPrice}
              discount={0.0}
            />
          )}
        </Article>
      </AnimatePresence>
      <Footer layout>
        <Box
          paddingY={{ base: 12, sm: 24 }}
          paddingX={24}
          backgroundColor="white"
        >
          {renderFooterButton()}
        </Box>
      </Footer>
    </CartDrawerWrapper>
  )
}
