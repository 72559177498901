import React, { VoidFunctionComponent } from 'react'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { OnboardingCartDrawer } from '@mindfulchefuk/features/Cart/components/CartDrawer/OnboardingCartDrawer'
import { updateOnboardingShopProduct } from '@mindfulchefuk/features/ShopOnboarding/shopOnboardingSlice'
import {
  logCloseCart,
  logRemoveItemFromCart,
  logRemoveAllItemFromCart,
  logProceedToCheckout,
  logAddItemFromCart,
} from '@mindfulchefuk/features/ShopOnboarding/utils/shopOnboardingAnalytics'
import { CartItemType } from '@mindfulchefuk/features/Cart/interfaces'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'
import { RootState } from '@mindfulchefuk/types/store'
import { useGetProductsWithQuantities } from '@mindfulchefuk/hooks/useGetProductsWithQuantities'
import { useGetProductLineItemsTotalPrice } from '@mindfulchefuk/hooks/useGetProductLineItemsTotalPrice'

type Props = {
  isDrawerOpen: boolean
  toggleDrawerOpen: (cartClick?: boolean) => void
  totalProducts: number
}

export const ShopOnboardingCartDrawerContainer: VoidFunctionComponent<
  Props
> = ({ isDrawerOpen, toggleDrawerOpen, totalProducts }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { isNewCustomer } = useIsNewCustomer()
  const lineItems = useSelector(
    (store: RootState) => store.shopOnboarding.lineItems,
    shallowEqual
  )

  const { data: products = [] } = useGetProductsWithQuantities({
    lineItems,
  })

  const { data: shopOrderTotal = 0 } = useGetProductLineItemsTotalPrice({
    lineItems,
  })

  const addProduct = (id: string) => {
    const selectedProduct = products.find((product) => product.id === id)

    dispatch(
      updateOnboardingShopProduct({
        productId: id,
        quantity: selectedProduct.quantity + 1,
      })
    )
    logAddItemFromCart(id)
  }

  const removeProduct = (id: string) => {
    const selectedProduct = products.find((product) => product.id === id)

    dispatch(
      updateOnboardingShopProduct({
        productId: id,
        quantity: selectedProduct.quantity - 1,
      })
    )
    logRemoveItemFromCart(id)
  }

  const removeAllProduct = (id: string) => {
    dispatch(
      updateOnboardingShopProduct({
        productId: id,
        quantity: 0,
      })
    )
    logRemoveAllItemFromCart(id)
  }

  return (
    <OnboardingCartDrawer
      isDrawerOpen={isDrawerOpen}
      toggleDrawerOpen={() => toggleDrawerOpen(true)}
      onPressContinue={() => {
        logProceedToCheckout(totalProducts.toString())
        toggleDrawerOpen()
        router.push(
          isNewCustomer
            ? routesConfig.postLoginOnboardingShipping.pathname
            : routesConfig.onboardingShopCheckoutAboutYou.pathname
        )
      }}
      total={shopOrderTotal}
      logCloseCart={() => logCloseCart(totalProducts.toString())}
      type={CartItemType.PRODUCT}
      products={products}
      addProduct={addProduct}
      removeProduct={removeProduct}
      removeAllProduct={removeAllProduct}
    />
  )
}
