import { useCallback } from 'react'
import {
  useQueryAllProducts,
  ProductWithQuantity,
  Products,
  LineItem,
} from '@mindfulchefuk/query'

interface Props {
  lineItems: LineItem[]
}

export const useGetProductsWithQuantities = ({ lineItems }: Props) => {
  const selectProductsWithQuantities = useCallback(
    (products: Products): ProductWithQuantity[] => {
      const shopProducts = lineItems.map((lineItem) => {
        const product = products.find(({ id }) => id === lineItem.productId)
        return { ...product, quantity: lineItem?.quantity || 0 }
      })

      return shopProducts.sort((a, b) => Number(b.inStock) - Number(a.inStock))
    },
    [lineItems]
  )

  return useQueryAllProducts({ select: selectProductsWithQuantities })
}
