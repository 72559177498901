import React, { VoidFunctionComponent } from 'react'
import { format } from 'date-fns'

import { RecipeWithQuantity } from '@mindfulchefuk/features/Recipes/interfaces'

import plural from '@mindfulchefuk/utils/plural'
import { ISODate } from 'lib/toISODateFormat'

import { mapRecipeToCartItem } from '@mindfulchefuk/features/Cart/components/transformers'
import { ItemList } from '@mindfulchefuk/features/Cart/components/CartDrawer/ItemList'
import { Section } from '@mindfulchefuk/features/Cart/components/CartDrawer/Section'

import {
  Box,
  ButtonLink,
  Flex,
  Notification,
  PriceText,
  Text,
} from '@mindfulchefuk/design-system'
import { CartItemType } from '@mindfulchefuk/features/Cart/interfaces'

type Props = {
  basketTotal: number
  basketOriginalTotal?: number
  basketTotalWithDelivery?: number
  isOpen: boolean
  maxRecipes: number
  minRecipes: number
  shippingPrice: number
  paymentDate: ISODate | Date
  portionCountPerMeal: number
  recipes: RecipeWithQuantity[]
  totalRecipes: number
  orderIsCompleted: boolean
  removeItem: (id: string, type: CartItemType) => void
  removeAllItem: (id: string, type: CartItemType) => void
  addItem: (id: string, type: CartItemType) => void
  viewReadyToGoRange?: () => void
}

export const Recipes: VoidFunctionComponent<Props> = ({
  basketTotal,
  basketOriginalTotal,
  basketTotalWithDelivery,
  isOpen,
  maxRecipes,
  minRecipes,
  shippingPrice,
  paymentDate,
  portionCountPerMeal,
  recipes = [],
  totalRecipes,
  orderIsCompleted,
  removeItem,
  removeAllItem,
  addItem,
  viewReadyToGoRange,
}) => {
  const minRecipesNotReached = totalRecipes < minRecipes
  const maxRecipesNotReached = totalRecipes < maxRecipes
  const recipeBoxFull = totalRecipes === maxRecipes
  const minRecipesToBeAdded = minRecipes - totalRecipes
  const maxRecipesToBeAdded = maxRecipes - totalRecipes
  const recipesToBeAddedText = plural('recipe')(totalRecipes)

  const getRecipesSelectedTitle = () => {
    if (recipeBoxFull) {
      return 'recipe box full'
    }
    return `${totalRecipes} ${recipesToBeAddedText} selected`
  }

  const getRecipesCalloutText = () => {
    if (minRecipesNotReached) {
      return `Add ${minRecipesToBeAdded} to ${maxRecipesToBeAdded} to continue`
    } else if (maxRecipesNotReached) {
      return `You can add up to ${maxRecipes}`
    } else if (recipeBoxFull) {
      if (orderIsCompleted || !viewReadyToGoRange) {
        return `Congrats on being so planet-friendly!`
      }
      return (
        <>
          You can still add items from our{' '}
          <ButtonLink
            as="button"
            color="inherit"
            variant="body-md"
            onClick={viewReadyToGoRange}
          >
            Ready to Go
          </ButtonLink>{' '}
          range to your order
        </>
      )
    }
  }

  return (
    <Section
      copy={
        <Flex justifyContent="space-between" alignItems="baseline">
          <Box>
            <Text variant="body-lg-500-caps" color="aubergine" pr={12} mb={8}>
              Your {portionCountPerMeal} person recipes
            </Text>
            <Text
              as="p"
              variant="body-md"
              color="aubergine"
              data-testid="cart-drawer-recipes-copy"
            >
              Confirm below and we’ll take payment on{' '}
              {format(paymentDate, 'dddd, MMM D')}.
            </Text>
          </Box>
          <Box textAlign="right">
            <Box>
              {basketOriginalTotal && (
                <PriceText
                  price={basketOriginalTotal}
                  isDiscounted={shippingPrice === 0}
                  variant="body-md"
                  mr={8}
                />
              )}
              <PriceText
                price={basketTotalWithDelivery ?? basketTotal}
                variant="heading-sm"
                data-testid="cart-drawer-recipe-total"
              />
            </Box>
            <Text
              color="aubergine"
              variant="heading-xxs"
              whiteSpace="nowrap"
              data-testid="cart-drawer-inc-shipping-text"
            >
              {shippingPrice ? (
                <>
                  incl. <PriceText price={shippingPrice} /> delivery
                </>
              ) : (
                <Text data-testid="free-delivery-text">with free delivery</Text>
              )}
            </Text>
          </Box>
        </Flex>
      }
    >
      <Notification
        id="recipes-section-info-text"
        my={14}
        type="inline"
        variant={minRecipesNotReached ? 'warning' : 'success'}
        icon="recipes"
        iconColor="aubergine"
        color="aubergine"
        title={getRecipesSelectedTitle()}
      >
        {getRecipesCalloutText()}
      </Notification>
      <Box mt={36}>
        <ItemList
          hideImagesOnMobile
          isOpen={isOpen}
          removeAllItem={(id) => {
            removeAllItem(id, CartItemType.RECIPE)
          }}
          removeItem={(id) => {
            removeItem(id, CartItemType.RECIPE)
          }}
          addItem={(id) => {
            addItem(id, CartItemType.RECIPE)
          }}
          items={recipes.map((recipe) => mapRecipeToCartItem(recipe))}
          showButtons
          canIncrement={totalRecipes < maxRecipes}
        />
      </Box>
    </Section>
  )
}
