import React, { ReactElement } from 'react'

import { CMSResponsiveImage } from '@mindfulchefuk/features/CMS/interfaces'
import { buildResponsiveImageSources } from '@mindfulchefuk/features/CMS/utils/buildResponsiveImages'

import { MarginProps } from '@mindfulchefuk/design-system/system'
import { CMSBannerLinkWrapper } from './CMSBannerLinkWrapper'
import { CMSImage } from './CMSImage'

type CMSModalBannerProps = {
  redirectURL: string
  clickHandler: () => void
  testId?: string
  margin?: MarginProps
  responsiveImage: CMSResponsiveImage
  altText: string
  imgTestId?: string
}

export const CMSModalBanner = ({
  redirectURL,
  clickHandler,
  testId = 'cms-promotional-banner',
  margin = { mb: 16 },
  responsiveImage,
  imgTestId = 'cms-promotional-banner-image',
  altText,
}: CMSModalBannerProps): ReactElement => {
  const responsiveImageSources = buildResponsiveImageSources(responsiveImage)

  return (
    <CMSBannerLinkWrapper
      href={redirectURL}
      onClick={clickHandler}
      testId={testId}
      {...margin}
    >
      <CMSImage
        src={responsiveImageSources.src}
        sources={responsiveImageSources.sources}
        alt={altText}
        data-testid={imgTestId}
      />
    </CMSBannerLinkWrapper>
  )
}
