import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from '@mindfulchefuk/types/store'
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from '@mindfulchefuk/services/localStorageAdapter'
import { LineItems } from '@mindfulchefuk/query'
import { setExistingShopProduct } from '@mindfulchefuk/features/ShopOnboarding/shopOnboardingSlice'
import { LINE_ITEMS } from '@mindfulchefuk/features/ShopOrder/constants'

// Declared in global scope to allow event listener to access
let lineItems: LineItems

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const typeCastLineItems = (maybeLineItems: any): LineItems => {
  try {
    const { lineItemId, productId, quantity } = (maybeLineItems as LineItems)[0]
    return lineItemId && productId && quantity && maybeLineItems
  } catch {
    return []
  }
}

export const useLocalStorageLineItems = (): void => {
  const dispatch = useDispatch()
  lineItems = useSelector((store: RootState) => store.shopOnboarding.lineItems)

  const beforeUnloadevent = () => {
    writeToLocalStorage({
      key: LINE_ITEMS,
      data: lineItems,
    })
  }

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadevent, true)
    return () =>
      window.removeEventListener('beforeunload', beforeUnloadevent, true)
  }, [])

  useEffect(() => {
    const lsLineItems = readFromLocalStorage({
      key: LINE_ITEMS,
      typeCheckCast: typeCastLineItems,
      deleteAfter: true,
    })
    dispatch(setExistingShopProduct(lsLineItems))
  }, [dispatch])
}
