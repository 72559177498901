import React, { useCallback, FunctionComponent } from 'react'

import MainLayout from '@mindfulchefuk/layouts/MainLayout'
import { Box } from '@mindfulchefuk/design-system'

import { shallowEqual, useSelector } from 'react-redux'
import { logOnCartClick } from '@mindfulchefuk/features/ShopOnboarding/utils/shopOnboardingAnalytics'
import OnboardingShopCartNavigation from '@mindfulchefuk/features/Cart/components/CartNavigation/OnboardingShopCartNavigation'
import { ShopOnboardingCartDrawerContainer } from '@mindfulchefuk/features/Cart/components/CartDrawer/ShopOnboardingCartDrawerContainer'
import { useCartDrawer } from '@mindfulchefuk/features/Cart/useCartDrawer'
import { instrumentBasketViewEvent } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentBasketViewEvent'
import getTotal from '@mindfulchefuk/utils/getTotal'
import { useRouter } from 'next/router'
import { RootState } from '@mindfulchefuk/types/store'
import {
  R2G_SHIPPING_PRICE,
  R2G_FREE_DELIVERY_MIN_PRICE,
} from '@mindfulchefuk/constants'
import { useGetProductLineItemsTotalPrice } from '@mindfulchefuk/hooks/useGetProductLineItemsTotalPrice'
import { useGetProductsWithQuantities } from '@mindfulchefuk/hooks/useGetProductsWithQuantities'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'

export const OnboardingShopLayout: FunctionComponent = ({ children }) => {
  const router = useRouter()
  const isCustomer = isAuthenticated()

  const productId = router.query.pid as string

  const cartReady = useSelector(
    (state: RootState) => state.shopOnboarding.lineItemsReady
  )
  const lineItems = useSelector(
    (store: RootState) => store.shopOnboarding.lineItems,
    shallowEqual
  )

  const { data: cartProducts = [] } = useGetProductsWithQuantities({
    lineItems,
  })

  const { data: shopOrderTotal = 0 } = useGetProductLineItemsTotalPrice({
    lineItems,
  })

  const orderQualifiesForFreeDelivery =
    shopOrderTotal - R2G_FREE_DELIVERY_MIN_PRICE > 0

  const totalProductsLength = getTotal(
    cartProducts.map((product) => product.quantity)
  )
  const pageSource = productId
    ? 'ready to go product page'
    : 'ready to go category page'

  const { isDrawerOpen, toggleDrawerOpen } = useCartDrawer()

  const handleLogCartClick = useCallback(() => {
    const deliveryAmount = orderQualifiesForFreeDelivery
      ? 0
      : R2G_SHIPPING_PRICE

    logOnCartClick(totalProductsLength.toString())
    instrumentBasketViewEvent({
      cartId: '',
      cartSize: totalProductsLength,
      productsLength: cartProducts.length,
      recipesLength: 0,
      totalRecipeAmount: 0,
      totalReadyToGoAmount: shopOrderTotal + deliveryAmount,
      totalBasketAmount: shopOrderTotal + deliveryAmount,
      deliveryAmount,
      pageSource,
      userIsLoggedIn: false,
    })
  }, [
    cartProducts.length,
    orderQualifiesForFreeDelivery,
    pageSource,
    shopOrderTotal,
    totalProductsLength,
  ])

  return (
    <MainLayout
      hideFooter
      hasStickyHeader
      title="Create a one off delivery"
      customNav={
        <Box ml={isCustomer ? 'auto' : undefined}>
          <Box ml={10}>
            <OnboardingShopCartNavigation
              toggleDrawerOpen={toggleDrawerOpen}
              logOnCartClick={handleLogCartClick}
              products={(cartReady && cartProducts) || null}
              totalProducts={totalProductsLength}
            />
          </Box>
        </Box>
      }
      isOnboarding
    >
      {children}
      <ShopOnboardingCartDrawerContainer
        isDrawerOpen={isDrawerOpen}
        totalProducts={totalProductsLength}
        toggleDrawerOpen={(cartClick: boolean) => {
          if (cartClick) {
            handleLogCartClick()
          }
          toggleDrawerOpen()
        }}
      />
    </MainLayout>
  )
}
