import { UpdateProductQuantityFn } from '@mindfulchefuk/features/Products/interfaces'
import { updateOnboardingShopProduct } from '@mindfulchefuk/features/ShopOnboarding/shopOnboardingSlice'
import {
  addToCartPixel,
  logAddToCart,
  logRemoveFromCart,
} from '@mindfulchefuk/features/ShopOnboarding/utils/shopOnboardingAnalytics'
import { ProductType } from '@mindfulchefuk/analytics'
import { instrumentProductAdded } from '@mindfulchefuk/utils/analytics/amplitude/instrument'
import { useDispatch } from 'react-redux'

export const useUpdateProductQuantityOnboarding =
  (): UpdateProductQuantityFn => {
    const dispatch = useDispatch()

    return ({ product, quantity }) => {
      const isAdd = quantity > product.quantity
      const isRemove = quantity < product.quantity

      if (isAdd) {
        logAddToCart(product)
        addToCartPixel(
          product.productType,
          product.price,
          product.name,
          product.id
        )

        instrumentProductAdded({
          productId: product.id,
          productSku: product.sku,
          productName: product.name,
          productType: 'ready to go',
          productCategory: product.productType as ProductType,
          productPrice: product.price,
          productQuantity: 1,
        })
      } else if (isRemove) {
        logRemoveFromCart(product)
      }

      dispatch(updateOnboardingShopProduct({ productId: product.id, quantity }))
    }
  }
