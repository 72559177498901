import { useCallback } from 'react'
import { useQueryAllProducts, Products, LineItem } from '@mindfulchefuk/query'

interface Props {
  lineItems: LineItem[]
}

export const useGetProductLineItemsTotalPrice = ({ lineItems }: Props) => {
  const selectTotal = useCallback(
    (products: Products): number => {
      return lineItems.reduce((sum, item) => {
        const { price } = products.find(({ id }) => id === item.productId)

        return sum + parseFloat(price.toString()) * item.quantity
      }, 0)
    },
    [lineItems]
  )

  return useQueryAllProducts({ select: selectTotal })
}
