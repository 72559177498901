import React, { VoidFunctionComponent } from 'react'

import { ProductWithQuantity } from '@mindfulchefuk/query'
import { CartItemType } from '@mindfulchefuk/features/Cart/interfaces'

import { mapProductToItem } from '@mindfulchefuk/features/Cart/components/transformers'
import { Section } from '@mindfulchefuk/features/Cart/components/CartDrawer/Section'
import { ItemList } from '@mindfulchefuk/features/Cart/components/CartDrawer/ItemList'

import { Box, Flex } from '@mindfulchefuk/design-system/layout'
import { Notification, PriceText, Text } from '@mindfulchefuk/design-system'
import { R2G_FREE_DELIVERY_MIN_PRICE } from '@mindfulchefuk/constants'

type Props = {
  orderIsCompleted: boolean
  shopOrderTotal: number
  isOpen: boolean
  products: ProductWithQuantity[]
  removeAllItem: (id: string, type: CartItemType) => void
  removeItem: (id: string, type: CartItemType) => void
  addItem: (id: string, type: CartItemType) => void
  shippingPrice: number
  shopOrderTotalBeforeDiscounts: number
  discount: number
}

const ANIMATION_DELAY = 2

export const Products: VoidFunctionComponent<Props> = ({
  orderIsCompleted,
  shopOrderTotal,
  isOpen,
  products = [],
  removeAllItem,
  removeItem,
  addItem,
  shippingPrice,
  shopOrderTotalBeforeDiscounts,
  discount,
}) => {
  const description = orderIsCompleted
    ? 'This has already been paid for'
    : 'Just review your order and pay now.'

  const showFreeDeliveryHint = !orderIsCompleted && !!shippingPrice

  return (
    <Section
      copy={
        <>
          <Flex justifyContent="space-between" alignItems="baseline">
            <Box>
              <Text variant="body-lg-500-caps" color="aubergine" pr={12} mb={8}>
                Your Ready to Go range items
              </Text>
              <Text
                as="p"
                variant="body-md"
                color="aubergine"
                data-testid="cart-drawer-products-review-copy"
              >
                {description}
              </Text>
            </Box>
            <Box textAlign="right">
              <PriceText
                as="p"
                price={shopOrderTotal}
                variant="heading-sm"
                data-testid="cart-drawer-product-total"
              />
              <Text
                color="aubergine"
                variant="heading-xxs"
                whiteSpace="nowrap"
                data-testid="cart-drawer-inc-shipping-text"
              >
                {shippingPrice ? (
                  <>
                    incl. <PriceText price={shippingPrice} /> delivery
                  </>
                ) : (
                  <Text data-testid="free-delivery-text">
                    with free delivery
                  </Text>
                )}
              </Text>
            </Box>
          </Flex>

          {showFreeDeliveryHint && (
            <Notification
              my={14}
              type="inline"
              variant="info"
              icon="delivery"
              data-testid="free-delivery-hint"
            >
              <Text as="p" variant="body-sm" pt={2}>
                Add another{' '}
                <Text as="span" variant="body-sm-500">
                  £
                  {(
                    R2G_FREE_DELIVERY_MIN_PRICE -
                    shopOrderTotalBeforeDiscounts +
                    discount
                  ).toFixed(2)}
                </Text>{' '}
                of Ready to Go range items to get{' '}
                <Text as="span" variant="body-sm-500">
                  FREE
                </Text>{' '}
                delivery (on orders over £{R2G_FREE_DELIVERY_MIN_PRICE})
              </Text>
            </Notification>
          )}
        </>
      }
    >
      <Box mt={36} pl={10}>
        <ItemList
          hideImagesOnMobile
          animationDelay={ANIMATION_DELAY}
          showButtons={!orderIsCompleted}
          isOpen={isOpen}
          removeAllItem={(id: string) => {
            removeAllItem(id, CartItemType.PRODUCT)
          }}
          addItem={(id: string) => {
            addItem(id, CartItemType.PRODUCT)
          }}
          removeItem={(id: string) => {
            removeItem(id, CartItemType.PRODUCT)
          }}
          items={products.map((product) =>
            mapProductToItem(product, {
              hasQuantityInHeading: !!orderIsCompleted,
            })
          )}
        />
      </Box>
    </Section>
  )
}
