import React, { ReactElement, ReactNode } from 'react'
import { GetStaticProps } from 'next'
import { dehydrate, QueryClient } from '@tanstack/react-query'
import { getCMSPage } from '@mindfulchefuk/features/CMS/services/getCMSPage'
import { useLocalStorageLineItems } from '@mindfulchefuk/hooks/useLocalStorageLineItems'
import ShopSelectionPage from '@mindfulchefuk/features/ShopOnboarding/pages/shop-selection'
import ShopOnboardingRedirect from '@mindfulchefuk/features/ShopOnboarding/components/ShopOnboardingRedirect'
import { OnboardingShopLayout } from '@mindfulchefuk/features/Products/layouts/OnboardingShopLayout'
import { getCMSGlobalContent } from '@mindfulchefuk/features/CMS/services/getCMSGlobalContent'

const ShopPage = () => {
  // persist order if the user refreshes the page
  useLocalStorageLineItems()

  return <ShopSelectionPage />
}

const OnboardingShop = (): ReactElement | null => {
  return (
    <ShopOnboardingRedirect>
      <ShopPage />
    </ShopOnboardingRedirect>
  )
}

OnboardingShop.getLayout = (page: ReactNode) => (
  <OnboardingShopLayout>{page}</OnboardingShopLayout>
)

export default OnboardingShop

export const getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient()
  await queryClient.prefetchQuery(['cms-page-shop-onboarding'], () =>
    getCMSPage('page-shop-onboarding')
  )
  await queryClient.prefetchQuery(['cms-global-content'], getCMSGlobalContent)

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: parseInt(process.env.NEXT_PUBLIC_CMS_REVALIDATE_INTERVAL, 10),
  }
}
